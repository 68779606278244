/* eslint-disable no-underscore-dangle */
import { useWindowWidth } from '@react-hook/window-size';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import { ArrowIcon } from '@/components/Icon';
import Text from '@/components/Text';

import ImmobiliensucheMobile from '../Module/ImmobiliensucheMobile';
import RechnerMobile from '../Module/RechnerMobile';
import StandardMobile from '../Module/StandardMobile';

const typeToWidget = {
  standard: StandardMobile,
  rechner: RechnerMobile,
  immobilien: ImmobiliensucheMobile,
};
const JourneyWidget = ({ modul, item, itemWidth, setChiptTitle }) => {
  const widgetModul = modul.replace('journey.', '').replace(/-/g, '');
  const Widget = typeToWidget[widgetModul];

  return <Widget widgetContent={item} itemWidth={itemWidth} setChiptTitle={setChiptTitle} />;
};

JourneyWidget.propTypes = {
  item: PropTypes.string.isRequired,
  itemWidth: PropTypes.string.isRequired,
  modul: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  setChiptTitle: PropTypes.func.isRequired,
};

const MyArrowIcon = styled(ArrowIcon)`
  path {
    fill: #a09e9e;
  }
`;

const Slider = () => {
  const { t: h } = useTranslation('content-journey');
  const items = h('items', {}, { returnObjects: true });
  const [[page], setPage] = useState([0, 0]);
  const [chipTitle, setChiptTitle] = useState('');
  const sliderRef = useRef(null);
  const [lengthItems] = useState(items.length - 1);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [animateValue, setAnimateValue] = useState(0);

  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);

  const [sliderConstraints, setSliderConstraints] = useState(0);
  useEffect(() => {
    const calcSliderWidth = () => {
      setSliderWidth(sliderRef.current.offsetWidth);
    };
    calcSliderWidth();
    window.addEventListener('resize', calcSliderWidth);

    const calcSliderChildrenWidth = () => {
      setSliderChildrenWidth(
        Array.from(sliderRef.current.childNodes).reduce((acc, node) => acc + node.offsetWidth + 16, 0)
      );
    };
    calcSliderChildrenWidth();

    const calcSliderConstraints = () => {
      setSliderConstraints(sliderChildrenWidth - sliderWidth);
    };
    calcSliderConstraints();
    window.addEventListener('resize', calcSliderConstraints);
    return () => {
      window.removeEventListener('resize', calcSliderWidth);
      window.removeEventListener('resize', calcSliderConstraints);
    };
  }, [sliderRef, sliderChildrenWidth, sliderWidth]);
  useEffect(() => {
    setChiptTitle(items[0].chip);
  }, [items]);

  const [itemWidth, setItemWidth] = useState(0);
  const width = useWindowWidth();
  useLayoutEffect(() => {
    setItemWidth(width * 0.8);
  }, [width]);
  function paginate(newDirection) {
    if (page === 0 && newDirection === 1) {
      setPage([0, 1]);
      setChiptTitle(items[0]?.chip);
    } else if (page === -lengthItems && newDirection === -1) {
      setPage([-lengthItems, -1]);
      setChiptTitle(items[-page]?.chip);
    } else {
      setPage([page + newDirection, newDirection]);
      setChiptTitle(items[-page - newDirection]?.chip);
    }
  }

  useEffect(() => {
    setAnimateValue(page * itemWidth + page * 20 + 30);
  }, [itemWidth, page]);
  // console.log('animateValue', animateValue);
  function onDragEnd(event, info) {
    const scrollY = (info.delta.x - info.offset.x) * -1;
    const offset = info.offset.x;
    const correctedVelocity = info.velocity.x;

    const startPosition = info.point.x - offset;
    const direction = correctedVelocity < 0 || offset < 0 ? 1 : -1;
    const endOffset = direction === 1 ? Math.min(correctedVelocity, offset) : Math.max(correctedVelocity, offset);
    const endPosition = startPosition + endOffset;

    const directionTest = correctedVelocity < 0 || offset < 0 ? 1 : -1;
    const dragAllowed = (startPosition - endPosition) * directionTest;

    if (dragAllowed > itemWidth * 0.4 && (scrollY < -50 || scrollY > 50)) {
      if (directionTest === -1) {
        paginate(1);
      } else {
        paginate(-1);
      }
    } else {
      setAnimateValue(page * itemWidth + page * 20 + 31);
      setAnimateValue(page * itemWidth + page * 20 + 30);
    }
  }
  return (
    <>
      <div
        style={{
          overflowX: 'hidden',
          width: '100%',
          marginTop: '1rem',
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          {page !== 0 ? (
            <Flex alignItems="center" ml="1rem" onClick={() => paginate(1)}>
              <ArrowIcon height="16px" width="16px" style={{ transform: 'rotate(270deg)' }} />
            </Flex>
          ) : (
            <Flex alignItems="center" ml="1rem">
              <MyArrowIcon height="16px" width="16px" style={{ transform: 'rotate(270deg)' }} />
            </Flex>
          )}
          <Text color="darkGrey" my={['1rem', '1rem', '1rem']} variant="headlineProjects">
            {chipTitle}
          </Text>
          {-lengthItems < page ? (
            <Flex alignItems="center" mr="1rem" onClick={() => paginate(-1)}>
              <ArrowIcon height="16px" width="16px" style={{ transform: 'rotate(90deg)' }} />
            </Flex>
          ) : (
            <Flex alignItems="center" mr="1rem">
              <MyArrowIcon height="16px" width="16px" style={{ transform: 'rotate(90deg)' }} />
            </Flex>
          )}
        </Flex>
        <motion.div
          ref={sliderRef}
          initial={{ x: 0 }}
          dragConstraints={{
            left: -sliderConstraints,
            right: 0,
          }}
          animate={{ x: animateValue }}
          transition={{ ease: 'easeOut', duration: 0.5, type: 'spring', bounce: 0.25 }}
          style={{ display: 'flex', alignItems: 'stretch' }}
          drag="x"
          dragDirectionLock
          onDragEnd={onDragEnd}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
          dragElastic={0.5}
          whileTap={{ cursor: 'grabbing' }}
        >
          {items.map((item) => {
            return <JourneyWidget key={item.id} item={item} modul={item.__component} itemWidth={itemWidth} />;
          })}
        </motion.div>
      </div>
    </>
  );
};

export default Slider;
