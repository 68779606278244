/* eslint-disable no-underscore-dangle */

import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';

import Section from '@/components/Section';
import Text from '@/components/Text';

import Slider from './Slider/Slider';

const JourneyMobile = () => {
  const { t } = useTranslation('page-indice');

  return (
    <>
      <Section bg="white" id="journey" padded>
        <Flex flexDirection="column" justifyContent="center" mb={['1.875rem', '3.75rem', '3.75rem']}>
          <Box mt={['2rem', '2rem', '2rem']} textAlign="center" width={['100%', '100%', '100%']}>
            <Text as="h2" color="darkGrey" textAlign="center" variant="title">
              {t('journey.title')}
            </Text>
            <Text variant="body" textAlign="center">
              {t('journey.text')}
            </Text>
          </Box>
        </Flex>
      </Section>
      <Section bg="white">
        <Slider />
      </Section>
    </>
  );
};

export default JourneyMobile;
