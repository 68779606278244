import { Form, Formik } from 'formik';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { number, object } from 'yup';

import { RightArrowIcon } from '@/components/Icon/ArrowIconUiKit';
import { Input } from '@/components/Inputs';
import FormButton from '@/components/Inputs/FormButton';
import Text from '@/components/Text';

const FWInput = (props) => <Input width="100%" {...props} />;

const RechnerMobile = ({ widgetContent, itemWidth }) => {
  const router = useRouter();
  const validationSchema = () => {
    const fields = {
      einkommen: number()
        .required('Diese Feld ist erforderlich')
        .integer('Zahl bitte')
        .typeError('Diese Feld ist erforderlich')
        .default('0'),
    };
    return object().shape(fields);
  };

  const [getInitialValues] = useState(() => {
    return {
      einkommen: widgetContent?.defaultValueEinkommen,
    };
  });

  const formikConfig = {
    initialValues: getInitialValues ?? validationSchema.cast(),
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      router.push({
        pathname: '/finanzierung/haushaltsrechner',
        query: {
          abschnitt: 'formular',
          netIncome: values.einkommen,
        },
      });
    },
  };

  return (
    <Flex
      bg="#3B4C5C"
      flexDirection={['column', 'column', 'column']}
      minWidth={itemWidth}
      mx="10px"
      overflow="hidden"
      sx={{ borderRadius: '10px' }}
      width={['100%', '100%', '45%']}
    >
      {/* <Box height={itemWidth} sx={{ position: 'relative' }}> */}
      <Box height="200px" sx={{ position: 'relative' }}>
        <NextImage
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          priority
          src={widgetContent.image.url}
        />
      </Box>

      <Flex width="100%" alignItems="center">
        <Box mx={['1.25rem', '1.25rem', '1.25rem']} my={['1.25rem', '1.25rem', '1.25rem']}>
          <Text color="#FFF" fontSize="1rem" mt={['0', '0', '0']} mb="1.875rem" variant="headline">
            {widgetContent.title}
          </Text>
          <Text color="#FFF" lineHeight={['1.375rem', '1.375rem', '1.375rem']} mb="1.875rem" variant="body">
            {widgetContent.text}
          </Text>

          <Formik {...formikConfig}>
            <Form>
              <Flex alignItems="flex-start" flexDirection="column" width="100%">
                <Box width="100%" mb={['1.25rem', '0', '0']}>
                  <FWInput
                    customFontSize="14px"
                    isInvert
                    name="einkommen"
                    placeholder=""
                    type="currency"
                    title={widgetContent.labelEinkommen}
                  />
                </Box>
                <FormButton
                  wrapperProps={{ width: '100%' }}
                  submit
                  Icon={RightArrowIcon}
                  text={widgetContent.buttonText}
                  variant="secondary"
                  my={['0', '6px']}
                />
              </Flex>
            </Form>
          </Formik>
        </Box>
      </Flex>
    </Flex>
  );
};

RechnerMobile.propTypes = {
  itemWidth: PropTypes.string.isRequired,
  widgetContent: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    defaultValueEinkommen: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    labelEinkommen: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default RechnerMobile;
