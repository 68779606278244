import NextImage from 'next/image';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import Text from '@/components/Text';
import FilterLight from '@/widgets/RealEstateSearch/FilterIndex';

const ImmobiliensucheMobile = ({ widgetContent, itemWidth }) => {
  return (
    <Flex
      bg="#3B4C5C"
      flexDirection={['column', 'column', 'column']}
      minWidth={itemWidth}
      mx="10px"
      overflow="hidden"
      sx={{ borderRadius: '10px' }}
      width={['100%', '100%', '45%']}
    >
      <Box height="200px" sx={{ position: 'relative' }}>
        <NextImage
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          priority
          src={widgetContent.image.url}
        />
      </Box>
      <Flex width="100%" alignItems="center">
        <Box mx={['1.25rem', '1.25rem', '1.25rem']} my={['1.25rem', '1.25rem', '1.25rem']}>
          <Text color="#FFF" fontSize="1rem" mb="1.875rem" mt={['0', '0', '0']} variant="headline">
            {widgetContent.title}
          </Text>
          <Text color="#FFF" lineHeight={['1.375rem', '1.375rem', '1.375rem']} mb="1.875rem" variant="body">
            {widgetContent.text}
          </Text>
          <Flex flexDirection="column" minWidth={['calc(100vw * 0.8 - 2.5rem )', '100%', '500px']}>
            <FilterLight />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

ImmobiliensucheMobile.propTypes = {
  itemWidth: PropTypes.string.isRequired,
  widgetContent: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImmobiliensucheMobile;
