import NextImage from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import Button from '@/components/Button/Button';
import { RightArrowIcon } from '@/components/Icon/ArrowIconUiKit';
import IconWrapper from '@/components/IconWrapper/IconWrapper';
import Text from '@/components/Text';

const StandardMobile = ({ widgetContent, itemWidth }) => {
  return (
    <Flex
      bg="#3B4C5C"
      flexDirection={['column', 'column', 'column']}
      minWidth={itemWidth}
      mx="10px"
      overflow="hidden"
      sx={{ borderRadius: '10px' }}
      width={['100%', '100%', '45%']}
    >
      {/* <Box height={itemWidth} sx={{ position: 'relative' }}> */}
      <Box height="200px" sx={{ position: 'relative' }}>
        <NextImage
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          priority
          src={widgetContent.image.url}
        />
      </Box>

      <Flex width="100%" alignItems="center">
        <Box mx={['1.25rem', '1.25rem', '1.25rem']} my={['1.25rem', '1.25rem', '1.25rem']}>
          <Text color="#FFF" fontSize="1rem" mt={['0', '0', '0']} mb="1.875rem" variant="headline">
            {widgetContent.title}
          </Text>
          <Text color="#FFF" mb="1.875rem" lineHeight={['1.375rem', '1.375rem', '1.375rem']} variant="body">
            {widgetContent.text}
          </Text>
          <Link href={widgetContent.link}>
            <a
              title={widgetContent.buttonText}
              rel={widgetContent?.newTab === false ? '' : 'noopener noreferrer'}
              target={widgetContent?.newTab === false ? '_self' : '_blank'}
            >
              <Button variant="secondary">
                <IconWrapper iconColor="black" Icon={RightArrowIcon}>
                  {widgetContent.buttonText}
                </IconWrapper>
              </Button>
            </a>
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
};

StandardMobile.propTypes = {
  itemWidth: PropTypes.string.isRequired,
  widgetContent: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default StandardMobile;
