import { useRouter } from 'next/router';
import * as React from 'react';

import { toast } from '@/components/Toaster/Toaster';
import { useBreakpoints } from '@/hooks';
import Seo from '@/widgets/SEO/Seo';
import CTABoxes from '@/widgets/Startpage/CTABoxes';
import Financing from '@/widgets/Startpage/Financing';
import Header from '@/widgets/Startpage/Header';
import Journey from '@/widgets/Startpage/Journey';
import JourneyMobile from '@/widgets/Startpage/JourneyMobile';
import TrustBox from '@/widgets/Startpage/TrustBox';

export default function Home() {
  const router = useRouter();
  const breakpoint = useBreakpoints();
  const isMobile = ['small'].includes(breakpoint);
  React.useEffect(() => {
    setTimeout(() => {
      toast({
        type: 'success',
        message: router.query.toast,
        dismissable: true,
        withIcon: true,
      });
    }, 1000);
  }, [router.query.toast]);

  return (
    <>
      <Seo page="page-start" />
      <Header />
      {isMobile ? <JourneyMobile /> : <Journey />}
      <TrustBox />
      <Financing />
      <CTABoxes />
    </>
  );
}

export async function getServerSideProps() {
  return {
    props: {},
  };
}
